/* Doodles Page */
.w-grid {
    width: 10%;
}

@media screen and (max-width: 1200px) {
    .w-grid {
        width: 20%;
    }
}

@media screen and (max-width: 768px) {
    .w-grid {
        width: 25%;
    }
}