/* Title Section */
#name {
    font-size: 16vw;
    line-height: 0.8em;
}

#label {
    font-size: 3vw;
}

@media screen and (max-width: 1200px) {
    #name {
        font-size: 16vw;
    }
}

@media screen and (max-width: 992px) {
    #name {
        font-size: 16vw;
    }
}

@media screen and (max-width: 768px) {
    #name {
        font-size: 20vw;
    }

    #label {
        font-size: 5vw;
    }
}

.carousel * {
    flex: 1;
}